.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: $brand-primary;
    transition: all 0.3s ease;
    box-shadow: inset 7px 0 9px -7px rgba(0,0,0,0.4);
    overflow-y: auto;

    li.hide-header {
      display: none;
    }

    a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #fff;
      font-size: 16px;
      padding: 12px 20px;

      &:hover, &:focus {
        background-color: transparent;
        color: $white;
      }
    }

    .dropdown-menu {
      right: 0;
      width: 100%;
      border-radius: 0;
      margin: 0;
      transform: none!important;
      position: relative!important;
      background-color: darken($brand-primary, 10);

      a {
        padding: 10px 25px;
      }
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}
