@keyframes bgcolorchange
    {
      0%   {background: $brand-primary; }
      50%  {background: darken($brand-primary, 10%); }
      100% {background: $brand-primary; }
    }

@keyframes bgcolorchangelight
    {
      0%   {background: $white; }
      50%  {background: darken($gray-light, 10%); }
      100% {background: $white; }
    }

.alert-banner {
  animation: bgcolorchangelight 2s infinite;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: 0.5s ease-in-out;
  will-change: opacity, height, max-height;

  .alert-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0;
    @include laptop {
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    @include desktop {
      padding: 10px 13px;
    }
    @include wide {
      padding: 10px 20px;
    }
  }

  .alert-text, .alert-buttons {
    @media screen and (max-width: ($width_laptop - 1px)) {
      @include flex(0, 0, 100%);
      text-align: center;
    }
  }

  p {
    color: $brand-primary;
    font-size: 18px;

    @include laptop {
      text-align: left;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }

  #linkBtn {
    margin-right: 10px;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    max-height: 200px;
  }
}



.home,
.page-template-template-landing,
.page-template-template-trip-type,
.page-template-template-destination {

  .alert-banner {
    animation: bgcolorchange 2s infinite;

    p {
      color: $white;
    }

    .btn {
      @extend .btn-light;
    }
  }

  header.banner {
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1001;
    padding-bottom: 0;

    .dropdown-menu {
      background-color: $blue-dark;

      a {
        color: $white;
      }
    }
  }

  &.header-normal header.banner {

    .logo-white {
      display: none;
    }

    .logo-dark {
      display: block;
    }

    .nav > li > a {
      color: $blue-dark;

      &:before {
        background-color: $blue-dark;
      }
    }

    .hamburger,
    .hamburger.is-active {
      span::before,
      span::after {
        background-color: $brand-primary;
      }
    }

    .hamburger span {
      background-color: $brand-primary;
    }

    .hamburger.is-active span {
      background-color: transparent;
    }


  }

}

header.banner {
  background-color: $brand-primary;
  padding-bottom: 8px;

  .logo-dark {
    display: none;
  }

  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include wide {
      padding-right: 15px;
    }
  }

  .brand {
    display: block;
    margin-left: -30px;
    width: 250px;
    @include mobile {
      width: auto;
      margin-left: -40px;
    }
    @include desktop {
      margin-left: -25px;
    }
  }

  .nav {
    > li {
      margin: 0 8px;
      @include desktop {
        margin: 0 13px;
      }
      @include wide {
        margin: 0 20px;
      }

      > a {
        display: block;
        font-family: $secondary-font;
        font-size: 12px;
        font-weight: 700;
        transition: none;
        text-transform: uppercase;
        color: $white;
        padding: 1px 0;
        position: relative;
        @include desktop {
          font-size: 13px;
        }
        @include wide {
          font-size: 15px;
        }

        &:before {
          content: '';
          position: absolute;
          display: block;
          transform: translateX(-50%);
          will-change: width;
          transition: $base-transition;
          background-color: $white;
          visibility: hidden;
          width: 0;
          left: 50%;
          top: 100%;
          height: 1px;
        }

        &:after {

        }

        &:hover, &:focus {
          &:before {
            width: 100%;
            visibility: visible;
          }
        }
      }

      &.current-menu-item a:before,
      &.active a:before,
      &.dropdown.show a:before {
        width: 100%;
        visibility: visible;
      }

      &.phone a:before {
        content: none;
      }

      &.hide-header {
        display: none;
      }
    }
  }

  .dropdown-menu {
    min-width: 100%;
    border-radius: 0;
    margin: 0;
    background-color: $white;
    opacity: 0;
    visibility: hidden;
    transition: $base-transition;
    display: block;
    padding: 10px 0;

    &.show {
      margin: 10px 0 0;
      visibility: visible;
      opacity: 1;
    }

    a {
      color: $blue-dark;
      font-family: $secondary-font;
      padding: 5px 15px;
      font-size: 12px;
      font-weight: 700;
      will-change: color, background-color;
      @include desktop {
        font-size: 13px;
      }
      @include wide {
        font-size: 15px;
      }

      &:hover, &:focus {
        background-color: $brand-primary;
        color: $white;
      }
    }

    .dropdown-menu {
      display: block;
      position: static!important;
      visibility: visible;
      opacity: 1;
      padding: 2px 8px;

      a {
        font-weight: 400;
      }
    }

  }
}


.specials-enabled header.banner {

  .brand {
    @include desktop {
      width: 300px;
    }
    @include wide {
      width: auto;
    }
  }

  .nav>li {
    @include desktop {
      margin: 0 10px;
    }
    @include wide {
      margin: 0 10px;
    }
    @include hd {
      margin: 0 15px;
    }

    a {
      @include desktop {
        font-size: 12px;
      }
      @include wide {
        font-size: 14px;
      }
      @include hd {
        font-size: 15px;
      }

    }
  }

}
