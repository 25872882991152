.hero {
  background-image: url("../images/hero-home.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 100px 0 70px;
  position: relative;
  @include mobile {
    padding: 120px 0 50px;
  }
  @include tablet {
    padding: 160px 0 100px;
  }
  @include laptop {
    padding: 160px 0 140px;
  }
  @include desktop {
    padding: 160px 0;
  }
  @include wide {
    padding: 280px 0 240px;
  }

  .cutout {
    position: absolute;
    bottom: 0;
    right: 30px;
    width: 550px;
    @include desktop {
      width: 650px;
    }
    @include wide {
      width: 850px;
    }
    @include hd {
      width: auto;
    }
  }

  p {
    margin-left: auto;
    margin-right: auto;
    @include laptop {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .h1 {
    max-width: 700px;
  }

  .subtext {
    max-width: 650px;
  }
}

.cta-content {
  text-align: center;
  @include laptop {
    text-align: left;
  }

  p {
    color: $white;
    text-shadow: 0 0 2.91px rgba(0, 66, 94, 1);

    &.subtext {
      font-weight: 500;
      margin-bottom: 0;
      font-size: 20px;
      @include tablet {
        font-size: 22px;
      }
      @include laptop {
        font-size: 24px;
      }
    }
  }

  .btn {
    margin-top: 25px;
  }
}

.strapline-panel {
  background-color: $brand-primary;
  text-align: center;

  p {
    font-weight: 400;
    color: $white;
    margin: 0 auto;
    max-width: 1050px;
    font-size: 18px;
    @include tablet {
      font-size: 22px;
    }
    @include wide {
      font-size: 24px;
    }
  }
}

.why-panel {
  text-align: center;
  border-top: 6px solid $gray-light;

  .panel-header {
    max-width: 750px;
    margin: 0 auto $section-padding;
    @include tablet {
      margin-bottom: ($section-padding + 10px);
    }
    @include laptop {
      margin-bottom: ($section-padding + 20px);
    }

    h2 {
      margin: 0 0 15px;
    }

    p {
      font-size: 18px;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .content-row {
    margin-bottom: -30px;
    @include desktop {
      justify-content: space-between;
    }
  }

  .content-col {
    margin-bottom: 30px;
    @include desktop {
       @include flex(0, 0, 280px);
    }

    p {
      margin-bottom: 0;
    }
  }

  .panel-footer {
    margin-top: $section-padding;
    @include tablet {
      margin-top: ($section-padding + 10px);
    }
    @include laptop {
      margin-top: ($section-padding + 20px);
    }
  }
}

.fishing-panel {
  position: relative;
  overflow: hidden;
  text-align: center;
  @include tablet {
    text-align: left;
  }

  &:before {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 0;
    height: 300px;
    display: block;
    width: 100%;
    background: linear-gradient(to bottom, rgba($blue-light,0.9) 0%,rgba(125,185,232,0) 100%);
  }

  .panel-content {
    position: relative;
    z-index: 300;
  }

  h2 {
    text-align: center;
  }

  .image-panel {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    @include tablet {
      flex-wrap: nowrap;
      height: 280px;
    }
    @include laptop {
      margin-bottom: -40px;
      height: 450px;
    }
    @include desktop {
      height: 475px;
    }
    @include wide {
      height: 520px;
    }
    @include hd {
      height: 560px;
    }

    &:last-of-type {
      margin-bottom: -20px;
      @include tablet {
        margin-bottom: -40px;
      }
    }

    h3.h2 {
      margin: 0 0 15px;
    }

    .image-block {
      height: 200px;
      width: 100%;
      position: relative;
      @include tablet {
        height: 100%;
        position: absolute;
        z-index: 300;
        top: 0;
        bottom: 0;
        width: $widthTablet;
        transform: skew(-15deg);
        overflow: hidden;
      }
      @include laptop {
        width: $widthLaptop;
      }
      @include desktop {
        width: $widthDesktop;
      }
      @include wide {
        width: $widthWide;
      }
      @include hd {
        width: $widthHD;
      }
    }

    .image {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      z-index: 300;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      @include tablet {
        transform: skew(15deg);
      }
    }

    .content-block {
      margin: 20px 0;
      @include tablet {
        margin: 0;
        width: 400px;
      }
      @include laptop {
        width: 300px;
        max-width: 100%;
        position: relative;
        z-index: 500;
      }
      @include desktop {
        width: 400px;
      }
      @include wide {
        width: 515px;
        padding: 0 15px;
      }
      @include hd {
        width: 535px;
      }
    }

    &:nth-of-type(odd) {
      justify-content: flex-end;
      z-index: 300;

      .image-block {
        @include tablet {
          left: -275px;
          right: auto;
        }
      }

      .image {
        @include tablet {
          left: 0;
          right: -90px;
        }
      }
    }

    &:nth-of-type(even) {
      justify-content: flex-end;
      z-index: 200;
      @include laptop {
        justify-content: flex-start;
      }

      .image-block {
        @include tablet {
          left: -275px;
        }
        @include laptop {
          right: -275px;
          left: auto;
        }
      }

      .image {
        @include tablet {
          right: -90px;
          left: 0;
        }
        @include laptop {
          right: 0;
          left: -90px;
        }
      }
    }
  }

  .image-panel-1 {

    .content-block {
      @include laptop {
        margin: 0 0 40px;
      }
    }

    &:after {
      @include laptop {
        content: '';
        position: absolute;
        bottom: -10px;
        top: 0;
        left: -275px;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
        width: calc(#{$widthLaptop} + 10px);
        transform: skew(-15deg);
        display: block;
      }
      @include desktop {
        width: calc(#{$widthDesktop} + 10px);
      }
      @include wide {
        width: calc(#{$widthWide} + 10px);
      }
      @include hd {
        width: calc(#{$widthHD} + 10px);
      }
    }
  }

  .image-panel-2 {

    .content-block {
      @include laptop {
        margin: 0;
      }
    }

    &:before {
      @include laptop {
        content: '';
        position: absolute;
        background: linear-gradient(35deg, rgba(131,188,215,1) 0%,rgba(131,188,215,0.4) 15%, rgba(126,186,229,0) 30%,rgba(125,185,232,0) 100%);
        top: 0;
        bottom: 0;
        left: -120px;
        width: 100%;
      }
      @include wide {
        left: -200px;
      }
    }
  }

  .image-panel-3 {

    .content-block {
      @include laptop {
        margin: 40px 0 0;
      }
    }

    &:before {
      @include laptop {
        content: '';
        position: absolute;
        background: linear-gradient(35deg, rgba(125,185,232,0) 0%,rgba(126,186,229,0) 70%, rgba(131,188,215,0.4) 85%, rgba(131,188,215,1) 100%);
        top: 40px;
        bottom: 0;
        right: -120px;
        width: 100%;
      }
      @include wide {
        right: -200px;
      }
    }

    &:after {
      @include laptop {
        content: '';
        position: absolute;
        top: -10px;
        bottom: 0;
        left: -275px;
        background: linear-gradient(to right, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 60%, rgba(255,255,255,1) 100%);
        width: calc(#{$widthLaptop} + 13px);
        transform: skew(-15deg);
        display: block;
      }
      @include desktop {
        width: calc(#{$widthDesktop} + 13px);
      }
      @include wide {
        width: calc(#{$widthWide} + 13px);
      }
      @include hd {
        width: calc(#{$widthHD} + 13px);
      }
    }
  }
}

.testimonials-panel {
  text-align: center;
  border-top: 6px solid $gray-light;
  @include tablet {
    border-top: 0;
  }

  .testimonial-carousel {
    max-width: 1120px;
    margin: 0 auto;

    &.slick-dotted {
      padding-bottom: 25px;
    }

    .slick-dots {
      bottom: 0;
      li {
        width: 10px;
        height: 10px;

        button {
          width: 10px;
          height: 10px;

          &:before {
            width: 10px;
            height: 10px;
            content: '';
            opacity: 1;
            border-radius: 50%;
            border: 2px solid $brand-primary;
          }
        }

        &.slick-active button:before {
          background-color: $brand-primary;
        }
      }
    }

    .slick-arrow {
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translate(-50%, -50%);
      bottom: -16px;
      z-index: 300;
      top: auto;

      &:before {
        font-family: FontAwesome;
        color: $brand-primary;
        opacity: 1;
      }

      &.slick-prev {
        left: calc(50% - 60px);
        &:before {
          content: '\f053';
        }
      }

      &.slick-next {
        left: calc(50% + 60px);
        &:before {
          content: '\f054';
        }
      }
    }
  }

  .testimonial {
    max-width: 310px;

    .quote {
      color: $gray-dark;
    }

    .author {
      font-family: $secondary-font;
      color: $brand-primary;
      font-weight: 700;
      font-size: 14px;
    }
  }

  .slick-slide {
    @include tablet {
      opacity: 0.5;
      will-change: opacity, transform;
      transition: $base-transition;
      transform: scale(0.9);
    }
  }

  .slick-center {
    @include tablet {
      opacity: 1;
      transform: scale(1);
    }
  }
}
