.hero.interior-hero {
  padding: 90px 0 40px;
  @include mobile {
    padding: 120px 0 40px;
  }
  @include tablet {
    padding: 130px 0 60px;
  }
  @include laptop {
    padding: 150px 0 100px;
  }
  @include desktop {
    padding: 160px 0 110px;
  }
  @include wide {
    padding: 180px 0 130px;
  }

  .cta-content {
    position: relative;
    z-index: 300;
  }
}


.header-normal .hero:before {
  content: '';
  display: block;
  @include stretch;
  background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0.3) 26%,rgba(255,255,255,0) 40%,rgba(255,255,255,0) 100%);
  z-index: auto;
}

.header-inverted .hero:before {
  content: '';
  @include stretch();
  background: linear-gradient(to bottom, rgba($black,0.6) 0%,rgba($black,0.4) 35%,rgba($black,0) 55%,rgba($black,0) 100%);
  z-index: auto;
}

.page-content-panel {
  border-top: 6px solid $gray-light;

  .h1,
  h1 {
    margin: 0 0 30px;
  }

  li,
  p {
    color: $black;
    font-weight: 400;
  }

  p {
    margin: 0 0 12px;
  }

  .h2,
  h2 {
    margin: 25px 0 10px;
  }

  .h3,
  h3 {
    margin: 20px 0 10px;
  }

  .video-container {
    margin-bottom: 20px;
  }

  .panel-footer {
    margin-top: 20px;
  }

  .contact-card {
    margin-top: 25px;
    @include laptop {
      margin-top: 0;
    }

    .h2,
    h2 {
      margin-top: 0;
    }
  }

  .heading-card {
    margin-bottom: 25px;
  }

  .trip-list {
    margin-top: 30px;
    margin-bottom: -25px;
    @include desktop {
      margin-top: 0;
    }
  }

  .trip-card {
    margin-bottom: 25px;

    img {
      margin-bottom: 15px;
      @include phablet {
        margin-bottom: 0;
      }
    }

    .h2,
    h2 {
      margin-top: 0;
    }

    .btn-row {
      .btn:first-of-type {
        margin-right: 8px;
      }
    }
  }
}

.content-card {
  background-color: $gray-light;
  padding: 20px;
  flex-grow: 1;
  @include desktop {
    padding: 20px 30px;
  }
}


#gmapContent h3 {
  margin-top: 0;
}

.trip-destinations {
  margin-bottom: -30px;
  h2 {
    margin: 40px 0 20px;
    @include laptop {
      margin-top: 0;
    }
  }

  .destination-card {
    margin-bottom: 30px;
    img {
      margin-bottom: 15px;
      @include tablet {
        margin-bottom: 0;
      }
    }
    h3 {
      margin-top: 0;
    }
  }
}

.page-gallery {
  margin-top: 20px;
  @include desktop {
    margin-top: 0;
  }

  .hide-temp {
    @include tablet {
      display: none!important;
    }
    @include laptop {
      display: block!important;
    }
  }

  .show-temp {
    display: none!important;
    @include tablet {
      display: block!important;
    }
    @include laptop {
      display: none!important;
    }
  }

  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -10px;

    .gallery-col {
      display: block;
      padding: 0 5px;
      margin-bottom: 10px;
      @include flex(0, 0, 50%);
      @include tablet {
         @include flex(0, 0, calc(1 / 3 * 100%));
      }
      @include laptop {
         @include flex(0, 0, 25%);
      }

      img {
        width: 100%;
      }
    }
  }

  .gallery-image {
    position: relative;
    display: block;

    &.hide-image {
      display: none;
    }

    &:after {
      content: '';
      @include stretch();
      background-color: rgba($black, 0.7);
      transition: $base-transition;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    .fa {
      transition: $base-transition;
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }

    &:focus,
    &:hover {
       &:after,
      .fa {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .open-gallery {
    @include aspect-ratio(1, 1);
    background-color: $brand-primary;
    transition: $base-transition;
    will-change: background-color;
    display: block;

    span {
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      font-weight: 500;

      small {
        display: block;
      }
    }

    &:focus,
    &:hover {
      background-color: $blue-dark;
    }
  }
}
