// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.gform_wrapper {
  ul {
    list-style: none;
    padding: 0;
  }

  .gfield {
    margin-bottom: 15px;

    label {
      font-weight: 500;
      text-transform: uppercase;
      color: $black;
    }

    input,
    textarea {
      // background-color: $gray-light;
      border: none;
      padding: 9px 10px;
      width: 100%;
      font-size: 16px;
    }

    textarea {
      height: 180px;
    }
  }

  .ginput_container_date {
    position: relative;

    input {
      padding-right: 35px;
    }

    &:after {
      font-family: FontAwesome;
      position: absolute;
      transform: translateY(-50%);
      color: $brand-primary;
      content: '\f073';
      right: 15px;
      top: 50%;
    }
  }

  .gform_footer {

    input[type=submit] {
      -webkit-appearance: none;
      -moz-appearance: none;
      @extend .btn;
      @extend .btn-primary;
      cursor: pointer;
    }
  }

  .g-recaptcha label {
    @extend .sr-only;
  }
}
