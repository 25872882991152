html {
  display: flex;
}

#body-wrapper {
  position: relative;
  min-height: 100vh;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  width: 1920px;
  max-width: 100%;
  margin: 0 auto;
}

body.sideNavBody { // any body class found on every page can be used here, this keeps styles from effecting the Wordpress editor

}

.wrap {
  flex: 1 1 auto;
  border-bottom: 6px solid $gray-light;
}

html, body {
  font-size: $body-font-size;
  font-weight: $body-font-weight;
  font-family: $body-font;
  color: $body-color;
  @include smooth-fonts;
  line-height: 1.2;
}

img {
  max-width: 100%;
  height: auto;
}

h1, .h1 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h1-weight;
  font-size: $h1-size;
  line-height: 1.25;
  @include tablet {
    font-size: ($h1-size + 4px);
  }
  @include laptop {
    font-size: ($h1-size + 8px);
  }
  @include desktop {
    font-size: ($h1-size + 10px);
  }
  @include wide {
    font-size: ($h1-size + 14px);
  }
}

h2, .h2 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h2-weight;
  font-size: $h2-size;
  line-height: 1.3;
  margin: 30px 0 15px;
  @include tablet {
    font-size: ($h2-size + 2px);
  }
  @include laptop {
    font-size: ($h2-size + 6px);
  }

  &.text-uppercase {
    margin: 0 0 $section-padding;
    @include tablet {
      margin-bottom: ($section-padding + 10px);
    }
    @include laptop {
      margin-bottom: ($section-padding + 20px);
    }
  }
}

h3, .h3 {
  color: $heading-color;
  font-family: $heading-font;
  font-weight: $h3-weight;
  font-size: $h3-size;
  line-height: 1.3;
  margin: 30px 0 15px;
  @include laptop {
    font-size: ($h3-size - 2px);
  }
  @include desktop {
    font-size: $h3-size;
  }
  @include wide {
    font-size: ($h3-size + 1px);
  }
}

h3.h2 {
  font-size: $h2-size;
  @include desktop {
    font-size: ($h2-size + 2px);
  }
  @include wide {
    font-size: ($h2-size + 6px);
  }
}

p, li {
  font-family: $body-font;
  font-size: $body-font-size;
  font-weight: $body-font-weight;
}

a {
  transition: $base-transition;
  &:hover, &:focus {
    text-decoration: none;
  }
}

ul.nav {
  list-style-type: none;
  padding: 0;

  .sub-menu {
    list-style-type: none;
    padding: 0;
  }
}

.no-wrap {
  white-space: nowrap;
}

.panel {
  padding: $section-padding 0;
  @include tablet {
    padding: ($section-padding + 10px) 0;
  }
  @include laptop {
    padding: ($section-padding + 20px) 0;
  }
}

.lg-outer .lg-item {
  background: url("../images/loading.gif") no-repeat scroll center center transparent;
}

.lg-object.lg-image {
  max-height: 90vh;
}

.video-container {
  position: relative;
  padding-bottom: 52.25%;
  padding-top: 35px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


.menu-item-467 {
  display: none;
}

.specials-enabled {
  .menu-item-467 {
    display: block;
  }
}
