.btn {
  font-size: 18px;
  font-family: $primary-font;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  border-radius: 0;
  padding: 15px 22px;

  &.btn-sm {
    font-size: 16px;
    padding: 10px 15px;
  }
}


a.link-text {
  font-family: $secondary-font;
  text-transform: uppercase;
  color: $brand-primary;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 700;
}
