.footer-cta {
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding: 60px 0 50px;
  @include tablet {
    padding: 90px 0 70px;
  }
  @include laptop {
    padding: 120px 0 100px;
  }
  @include desktop {
    padding: 180px 0 160px;
  }
}


footer.content-info {
  text-align: center;

  .footer-content {
    background-color: $brand-primary;
    padding: 30px 0;

    p, a {
      color: $white;
    }
  }

  .brand {
    display: block;
    margin-bottom: 20px;
  }

  .contact-info {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    > span {
      line-height: 1.1;
      display: block;
      margin: 5px 0;
      width: 100%;
      @include tablet {
        display: inline-block;
        padding: 0 15px;
        width: auto;
      }
      @include laptop {
        margin: 0;
      }
    }

    .address {
      @include tablet {
        border-right: 1px solid $white;
      }
    }

    .phone {

      @include laptop {
        border-right: 1px solid $white;
      }
    }
  }

  .sub-menu {
    // display: none;
  }

  .nav {
    justify-content: center;
    flex-direction: column;
    margin-bottom: 10px;
    @include tablet {
      flex-direction: row;
      margin-bottom: 0;
    }

    li {
      margin: 0 15px;
      a {
        display: block;
        position: relative;
        padding: 1px 0;

        &:after {
          @include tablet {
            content: '';
            position: absolute;
            display: block;
            transform: translateX(-50%);
            will-change: width;
            transition: $base-transition;
            background-color: $white;
            visibility: hidden;
            width: 0;
            left: 50%;
            top: 100%;
            height: 1px;
          }
        }

        &:hover, &:focus {
          &:after {
            width: 100%;
            visibility: visible;
          }
        }
      }

      &.hide-footer {
        display: none!important;
      }

    }
  }

  .trout-unlimited {
    display: block;
    text-align: center;

    img {
      height: 140px;
      margin-top: 15px;
      margin-bottom: 20px;
      @include tablet {
        margin-bottom: 0;
      }
    }
  }
}


.copyright {
  background-color: $blue;
  padding: 12px 0;

  div, a {
    color: $white;
    font-size: 12px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include laptop {
      justify-content: space-between;
    }

    .links-copy {
      margin-bottom: 8px;
      @include tablet {
        margin-bottom: 2px;
      }
      @include laptop {
        margin-bottom: 0;
      }
    }
  }
}
