$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";

// Colors
$white:                 #fff;
$gray-100:              #f8f9fa;
$gray-600:              #868e96;
$gray-800:              #343a40;
$red:                   #dc3545;
$yellow:                #ffc107;
$green:                 #28a745;
$cyan:                  #83bcd7;

$blue-dark:             #31406b;
$brand-primary:         #0b5e82;
$blue:                  #1b6484;
$blue-light:            #83bcd7;
$gray-dark:             #646464;
$gray:                  #828282;
$gray-light:            #e1e1e1;

$theme-colors: (
  primary:              $brand-primary,
  secondary:            $gray-600,
  success:              $green,
  info:                 $cyan,
  warning:              $yellow,
  danger:               $red,
  light:                $gray-100,
  dark:                 $gray-dark
);

// Fonts
$primary-font:          'futura-pt', sans-serif;
// Book     400
// Medium   500
// Heavy    700
$secondary-font:        'Libre Baskerville', serif;
// Regular  400
// Bold     700
$body-font-size:        16px;
$body-font-weight:      500;
$body-font:             $primary-font;
$body-color:            $gray;
$heading-font:          $secondary-font;
$heading-color:         $brand-primary;
$h1-weight:             700;
$h1-size:               30px;
$h2-weight:             700;
$h2-size:               22px;
$h3-weight:             700;
$h3-size:               18px;

// Base Styles
$base-transition:       0.3s ease-in-out;
$section-padding:       30px;

// Image Panel Widths
$widthTablet: 70%;
$widthLaptop: 88%;
$widthDesktop: 82.5%;
$widthWide: 77.5%;
$widthHD: 75%;

//Side-Nav
$sideNavWidth: 260px;
$sideNavDirection: (right);

// Hamburger
$button_width: 40px;     // width of button area
$button_height: 60px;     // height of button area
$bar_thickness: 6px;     // thickness of button bars
$bar_roundness: 0;
$button_pad: 0px;      // left/right padding between button area and bars.
$button_bar_space: 8px;     // spacing between button bars
$button_transistion_duration: 0.3s;  // transition duration
$hamburger_normal: $white;
$hamburger_active: $white;

// Bootstrap Variables
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  wd: 1440px,
  hd: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px,
  wd: 1400px,
  hd: 1560px
);

// Breakpoint variables
$width_mobile:          480px;
$width_phablet:         576px;
$width_tablet:          768px;
$width_laptop:          992px;
$width_desktop:         1200px;
$width_wide:            1440px;
$width_hd:              1600px;

/*
 * Mixins
 */
// Media query mixins
@mixin mobile { @media (min-width: #{$width_mobile}) { @content; } }
@mixin phablet { @media (min-width: #{$width_phablet}) { @content; } }
@mixin tablet { @media (min-width: #{$width_tablet}) { @content; } }
@mixin laptop { @media (min-width: #{$width_laptop}) { @content; } }
@mixin desktop { @media (min-width: #{$width_desktop}) { @content; } }
@mixin wide { @media (min-width: #{$width_wide}) { @content; } }
@mixin hd { @media (min-width: #{$width_hd}) { @content; } }

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin stretch($offset: 0) {
  position: absolute;
  top: #{$offset};
  right: #{$offset};
  left: #{$offset};
  bottom: #{$offset};
  z-index: 99;
}

@mixin smooth-fonts() {
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($letter-spacing: 0) {
  letter-spacing: calc(#{$letter-spacing} * 0.001em);
}

// Flexbox mixin - prevents most IE 11 issues
@mixin flex($grow: 0, $shrink: 0, $basis: 100%) {
  flex: #{$grow} #{$shrink} #{$basis};
  max-width: #{$basis};
}
